import React from "react";
import { useHistory } from "react-router-dom";
import { BSBtnDropdown } from "traec-react/utils/bootstrap/";
import { isSuperuser } from "traec-react/utils";
import { confirmDelete } from "traec-react/utils/sweetalert";
import Im from "immutable";
import useApi, { doFetch, fetcher } from "storybook-dashboard/utils/fetching";
import { distance } from "fastest-levenshtein";

const deleteCompany = (e, company, trigger) => {
  e.preventDefault();
  if (!company) {
    return null;
  }
  let companyId = company?.get("uid");
  let companyName = company.get("name");
  confirmDelete({
    text: `This will delete the company: ${companyName}.  Are you sure you would like to proceed?`,
    onConfirm: () => {
      doFetch(`/api/company/${companyId}/`, "DELETE").then((response) => {
        console.log("Deleted company", companyId, response);
        trigger();
      });
    },
  });
};

function ItemMenu({ company, user }) {
  let { trigger } = useApi("/api/company/");
  if (!isSuperuser(user)) {
    return null;
  }

  return (
    <span style={{ cursor: "pointer" }}>
      <BSBtnDropdown links={[{ name: "Delete", onClick: (e) => deleteCompany(e, company, trigger) }]} />
    </span>
  );
}

function CompanyChildren({ user, company, companiesById, indentLevel = 0 }) {
  let childids = company?.get("childids") || Im.List();
  return childids.map((childid, i) => (
    <CompanyItem
      key={i}
      index={i}
      indentLevel={indentLevel + 1}
      company={companiesById.get(childid)}
      companiesById={companiesById}
      user={user}
    />
  ));
}

const getUrl = (companyId, projectId, refId) => {
  if (!projectId && !refId) {
    return `/company/${companyId.substring(0, 8)}`;
  }
  if (!refId) {
    return `/project/${projectId.substring(0, 8)}`;
  }
  return `/project/${projectId.substring(0, 8)}/wpack/${refId?.substring(0, 8)}`;
};

const clickLink = (e, company, indentLevel, history) => {
  console.log("Clicked link to company", company?.toJS());
  let companyId = company?.get("uid");
  let projectId = company
    ?.get("projects")
    ?.sortBy((i) => distance(i.get("name")?.toLowerCase(), "reporting suppliers"))
    ?.first()
    ?.get("uid");
  // If you click on a root-level company (like Industry Dashboard or Partners) then
  // go to that company page
  if (indentLevel == 0) {
    history.push(getUrl(companyId));
    return null;
  }
  // If you click on a company under a Industry dashboard account then make some calls to determine the reporting package
  // and redirect to that.
  if (company?.get("parentid") === "9d7c6f40-0669-4025-9604-210b6f63f4cb") {
    console.log("Industry dashboard redirect", companyId, projectId);
    fetcher(`/api/project/${projectId}/`)
      .then(({ payload: project }) => {
        console.log("Got project information", project?.toJS());
        let trackerId = project?.getIn(["trackers", 0, "uid"]);
        return trackerId ? fetcher(`/api/tracker/${trackerId}/ref/`) : {};
      })
      .then(({ payload: refs }) => {
        console.log("Got reporting package information", refs?.toJS());
        let refId = refs
          ?.filter((i) => !(i.get("name") === "master"))
          ?.first()
          ?.get("uid");
        history.push(getUrl(companyId, projectId, refId));
      });
    return null;
  }
  // Otherwise redirect to the project level (reporting suppliers) if it exists, else the company level
  history.push(getUrl(companyId, projectId));
};

export default function CompanyItem(props) {
  let history = useHistory();

  console.log(history, "history <<<");
  let { company, indentLevel, user } = props;
  if (!company?.get("name")) {
    return null;
  }

  return (
    <>
      <div className="row my-2 p-2 row-hover-effect">
        <div className="col-sm-12">
          <span
            className="btn-sm btn-link"
            style={{
              marginLeft: `${indentLevel * 2}em`,
              cursor: "pointer",
            }}
            onClick={(e) => clickLink(e, company, indentLevel, history)}
          >
            {company.get("name")}
          </span>
          <ItemMenu user={user} company={company} />
        </div>
      </div>
      <CompanyChildren {...props} />
    </>
  );
}
